import { CSSProperties, FC } from 'react'
import ReactSelect, { MultiValue, SingleValue } from 'react-select'
import Feedback from '../feedback'
import { IFeedback } from '../types'

export interface IOption {
  value: string
  label?: string | JSX.Element
  readonly color?: string
  readonly isFixed?: boolean
  readonly isDisabled?: boolean
}

export interface GroupedOption {
  readonly label: string
  readonly options: readonly IOption[]
}

interface IProps extends IFeedback {
  options: IOption[] | GroupedOption[]
  defaultValue?: IOption
  value?: IOption
  name: string
  onChange?: (newValue: MultiValue<IOption> | SingleValue<IOption>) => void
  readonly isClearable?: boolean
  readonly isDisabled?: boolean
  readonly isLoading?: boolean
  readonly isRtl?: boolean
  readonly isSearchable?: boolean
  readonly isMulti?: boolean
  placeholder?: string
  feedbackText?: string
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}
const groupBadgeStyles: CSSProperties = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center'
}

const formatGroupLabel = (data: GroupedOption) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

// const formatOptionLabel = (data: GroupedOption) => {
//   return (
//     <div className="flex justify-between">
//       <span>{data.label}</span>
//     </div>
//   )
// }

const DropdownIndicator = () => {
  return (
    <span className="w-8 h-full inline-flex justify-center items-center">
      {/* <i className="fa fa-caret-down" />
       */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.293 7.293a1 1 0 0 1 1.414 0L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414z"
          fill="#374151"
        />
      </svg>
    </span>
  )
}

const Select: FC<IProps & { className?: string }> = ({
  options,
  defaultValue,
  value,
  isClearable,
  isDisabled,
  isLoading,
  isRtl,
  isSearchable,
  isMulti,
  placeholder,
  onChange,
  showState,
  showErrorOnly,
  state,
  feedbackText,
  className
}) => {
  const customStyles = {
    control: (provided: any, inputState: any) => ({
      ...provided,
      borderRadius: 6,
      height: 48,
      boxShadow: 'none',
      borderColor: '#d1d5db',
      '&:hover': {
        borderColor: inputState.isFocused && '#1b84e7'
      },

      color: '#111827'
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: 6,
      padding: '4px 0',
      marginTop: 0,
      boxShadow:
        '0 0 0 1px rgba(0, 0, 0, 0.05), 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 10px 15px -3px rgba(0, 0, 0, 0.1)',
      border: 'none'
    }),
    option: (provided: any, inputState: any) => ({
      ...provided,
      padding: '8px 12px',
      color:
        inputState.isFocused && inputState.isSelected
          ? '#1890ff'
          : inputState.isSelected
          ? '#1890ff'
          : inputState.isFocused
          ? '#111827'
          : '#111827',
      backgroundColor:
        inputState.isFocused && inputState.isSelected
          ? '#fff'
          : inputState.isSelected
          ? 'transparent'
          : inputState.isFocused
          ? '#fff'
          : inputState.isDisabled
          ? '#fff'
          : 'transparent',
      cursor: inputState.isDisabled ? 'not-allowed' : 'pointer',
      '&:hover': {
        backgroundColor: !inputState.isDisabled && '#fff',
        color:
          !inputState.isDisabled &&
          (inputState.isSelected ? '#1890ff' : '#111827')
      }
    }),
    multiValue: (provided: any, inputState: any) => ({
      ...provided,
      backgroundColor: inputState.isDisabled ? '#adb5bd' : '#1b84e7',
      borderRadius: 0,
      flexDirection: 'row-reverse'
    }),
    multiValueLabel: (provided: any, inputState: any) => ({
      ...provided,
      color: '#fff'
      // paddingRight: 12,
      // paddingLeft: inputState?.isDisabled ? 10 : 0,
      // paddingTop: 8
    }),
    multiValueRemove: (provided: any, inputState: any) => ({
      ...provided,
      color: '#fff',
      opacity: 0.5,
      padding: '8px 12px',
      display: inputState.isDisabled ? 'none' : 'inline',
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#fff',
        opacity: 1
      }
    }),
    indicatorSeparator: () => ({
      display: 'none'
    })
  }
  return (
    <>
      <ReactSelect
        styles={customStyles}
        options={options}
        defaultValue={defaultValue}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isRtl={isRtl}
        isSearchable={isSearchable}
        isMulti={isMulti}
        formatGroupLabel={formatGroupLabel}
        placeholder={placeholder}
        components={{ DropdownIndicator }}
        value={value}
        onChange={onChange}
        className={className}
      />
      {feedbackText && showState && (
        <Feedback state={state} showErrorOnly={showErrorOnly}>
          {feedbackText}
        </Feedback>
      )}
    </>
  )
}
Select.displayName = 'Select'

export default Select
